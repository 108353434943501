import React, { useState, useCallback, useEffect, useRef } from "react";
import { useLocation, matchPath } from "react-router";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useGetTheme } from "./hooks";
import { useFeatureFlags } from "./hooks/useFeatureFlags";
import makeStyles from "@mui/styles/makeStyles";
import { Box, styled } from "@mui/material";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { doc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { changeSidebarWidth } from "./redux/userSlice";
import { FEATURE_FLAGS } from "./consts";

const ResizeHandle = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  width: 4,
  height: "100%",
  cursor: "col-resize",
  backgroundColor: theme.palette.grey[300],
  ...theme.applyStyles("dark", {
    backgroundColor: "#555555"
  }),
  "&:hover": {
    backgroundColor: theme.palette.grey[400],
    ...theme.applyStyles("dark", {
      backgroundColor: "#777777"
    })
  },
  zIndex: 1000,
  touchAction: "none"
}));

const DotHandle = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  right: -16,
  width: "8px",
  height: "40px",
  marginLeft: "6px",
  marginRight: "6px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  cursor: "col-resize",
  backgroundColor: "transparent",
  transform: "translateY(-50%)",
  zIndex: 1001,
  "&:focus": {
    outline: "none",
    border: "1px solid white"
  },
  "&:focus .dot": {
    backgroundColor: "theme.palette.primary.main"
  }
}));

const Dot = styled(Box)(() => ({
  width: 4,
  height: 4,
  borderRadius: "50%",
  backgroundColor: "rgba(128, 128, 128)",
  transition: "background-color 0.2s"
}));

const useStyles = makeStyles(() => {
  return {
    sidebar: {
      display: "flex",
      flexShrink: 0,
      position: "relative"
    },
    noSelect: {
      userSelect: "none",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      msUserSelect: "none"
    }
  };
});

export default function Sidebar({ children, routes }) {
  const classes = useStyles();
  const theme = useGetTheme();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const location = useLocation();
  const userId = useSelector((state) => state.firebase.auth.uid);

  const isAdjustableSidebarEnabled = useFeatureFlags(
    FEATURE_FLAGS.ADJUSTABLE_SIDEBAR
  );
  const [sidebarWidth, setSidebarWidth] = useState(384);
  const [isResizing, setIsResizing] = useState(false);
  const [maxWidth, setMaxWidth] = useState(window.innerWidth * 0.5);
  const initialFirestoreWidth = useRef(null);
  const dotHandleRef = useRef(null);

  const userConfigRef = doc(
    firestore,
    "userProfiles",
    "customConfig",
    userId,
    "CROSS_FEATURE"
  );
  const { data: userConfig } = useFirestoreDocData(userConfigRef);

  const shouldRenderSidebar =
    routes?.some((route) =>
      matchPath(
        {
          path: route,
          end: true
        },
        location.pathname
      )
    ) ?? true;

  useEffect(() => {
    if (
      userConfig &&
      userConfig.sidebarWidth &&
      initialFirestoreWidth.current === null &&
      isAdjustableSidebarEnabled
    ) {
      initialFirestoreWidth.current = userConfig.sidebarWidth;
      setSidebarWidth(userConfig.sidebarWidth);
    }
  }, [userConfig]);

  const startResizing = useCallback(
    (e) => {
      e.preventDefault();
      setIsResizing(true);
      document.body.classList.add(classes.noSelect);
    },
    [classes.noSelect]
  );

  const stopResizing = useCallback(() => {
    setIsResizing(false);
    document.body.classList.remove(classes.noSelect);
  }, [classes.noSelect]);

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        const newWidth = mouseMoveEvent.clientX;
        if (newWidth >= 250 && newWidth <= maxWidth) {
          dispatch(changeSidebarWidth(newWidth));
          setSidebarWidth(newWidth);
        }
      }
    },
    [isResizing, maxWidth, dispatch, changeSidebarWidth]
  );

  const handleKeyDown = useCallback(
    (e) => {
      const step = e.shiftKey ? 10 : 1;
      let newWidth = sidebarWidth;

      if (e.key === "ArrowLeft") {
        newWidth = Math.max(250, sidebarWidth - step);
      } else if (e.key === "ArrowRight") {
        newWidth = Math.min(maxWidth, sidebarWidth + step);
      } else {
        return; // Early return for other keys
      }

      e.preventDefault(); // Prevent default behavior for arrow keys
      dispatch(changeSidebarWidth(newWidth));
      setSidebarWidth(newWidth);

      // Ensure the handle keeps focus
      if (dotHandleRef.current) {
        dotHandleRef.current.focus();
      }
    },
    [sidebarWidth, maxWidth, dispatch, changeSidebarWidth]
  );

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    const handleResize = () => {
      const newMaxWidth = window.innerWidth * 0.5;
      setMaxWidth(newMaxWidth);

      let newWidth;
      if (
        window.matchMedia(
          theme.breakpoints.up("desktop").replace("@media ", "")
        ).matches
      ) {
        newWidth = Math.min(468, newMaxWidth);
      } else {
        newWidth = Math.min(384, newMaxWidth);
      }

      if (initialFirestoreWidth.current !== null) {
        newWidth = Math.min(initialFirestoreWidth.current, newMaxWidth);
      }

      setSidebarWidth(newWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [theme.breakpoints]);

  if (!shouldRenderSidebar) {
    return null;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box className={classes.sidebar} style={{ width: `${sidebarWidth}px` }}>
          {children}
          {isAdjustableSidebarEnabled && (
            <>
              <DotHandle
                ref={dotHandleRef}
                onMouseDown={startResizing}
                onTouchStart={startResizing}
                tabIndex={0}
                role="slider"
                aria-valuenow={sidebarWidth}
                aria-valuemin={250}
                aria-valuemax={maxWidth}
                aria-label="Resize sidebar"
                onKeyDown={handleKeyDown}>
                <Dot className="dot" />
                <Dot className="dot" />
                <Dot className="dot" />
              </DotHandle>
              <ResizeHandle
                onMouseDown={startResizing}
                onTouchStart={startResizing}
              />
            </>
          )}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
