// Dependencies
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { httpCallables } from "../../../firebase";
import { useQuery, useResizeObserver, useStepStage } from "../../../hooks";
import {
  removeAllAnotationsOfType,
  getHighlightColor,
  mergeOverlappingHighlightsBySelection,
  clearSelection,
  getEpubContentInRange,
  fetchAllUserHighlightsPerText
} from "../utils";
import { interactionsAPI } from "../../../api";
import { useRendition } from "../../../RenditionContext";
import {
  ANNOTATION_TYPES,
  COMMENT_PANEL_VIEW,
  INTERACTION_SUBTYPES,
  INTERACTION_TYPES
} from "../../../consts";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { selectTask, selectSubmission } from "../../../redux/tasksSlice";
import {
  isPdfSelector,
  selectText,
  setTextUrl
} from "../../../redux/textsSlice";
import {
  selectedQuestionHighlights,
  selectCurrentInteraction,
  selectGrStepOneHighlights,
  selectCurrentTaskHighlights,
  selectReaderHighlights,
  addHighlight as addHighlightReducer
} from "../../../redux/interactionsSlice";
import { selectCourseByTextId } from "../../../redux/coursesSlice";
import {
  toggleNoQuestionsMessage,
  toggleAnnotatorBar,
  setSelectedHighlight,
  openAnnotatorBar
} from "../../../redux/highlightSlice";
import {
  selectDarkMode,
  selectIsComments
} from "../../../redux/firestoreSelectors";
import {
  selectThread,
  setCommentPanelState,
  setNewCommentObject
} from "../../../redux/realtimeInteractionsSlice";
import { setCustomCursor } from "../../../redux/layoutSlice";

// Components
import EpubView from "../EpubView/EpubView";
import ReactReaderActions from "../../annotations/ReactReaderActions";

// Material UI
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFirestoreConnect } from "../../../hooks/useFirestoreConnect";
import { useHighlightColor } from "../../../hooks/useHighlightColor";

// Styles
const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    position: "relative"
  }
}));
function ReactReader({ minimalBar, readOnly, disableInteractions = false }) {
  const { submission_id } = useQuery();

  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const highlightsRef = useRef([]);
  const { rendition } = useRendition();
  const { width } = useResizeObserver(containerRef);

  //Redux State
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const user_id = useSelector((state) => state.firebase.auth.uid);

  const task = useSelector((state) => selectTask(state, submission.task_id));
  const isCommentsInSecondarySidebar = useSelector((state) =>
    selectIsComments(state)
  );
  const readerHighlights = useSelector(selectReaderHighlights);
  const grStepOneHighlights = useSelector(selectGrStepOneHighlights);
  const questionHighlights = useSelector(selectedQuestionHighlights);
  const taskHighlights = useSelector(selectCurrentTaskHighlights);
  const socialHighlights = useSelector(selectThread);

  const action = useSelector(
    (state) => state.readerActions.persistentActionState.actionBar
  );
  const darkMode = useSelector((state) => selectDarkMode(state));
  const annotatorMode = useSelector(
    (state) => state.readerActions.persistentActionState.annotatorMode
  );
  const detachedComment = useSelector(
    (state) => state.readerActions.detachedComment
  );

  const [step] = useStepStage();
  const showHighlights = useSelector(
    (state) => state.readerActions.persistentActionState.showHighlights
  );
  const showAllHighlights = useSelector(
    (state) => state.readerActions.persistentActionState.showAllHighlights
  );

  const fontSizeValue = useSelector((state) => state.user.userProfile.fontSize);
  const fontSize = useSelector((state) => state.user.fontSizeOptions);
  const shownLocation = useSelector((state) => {
    return state.readerActions.shownLocation;
  });
  const selectedTextId = useSelector((state) => {
    return state.texts.selectedTextId;
  });
  const text = useSelector((state) => selectText(state, selectedTextId));
  const selectedCourse = useSelector(selectCourseByTextId);
  const selectedQuestion = useSelector(selectCurrentInteraction);
  const questions = useSelector((state) => state.interactions.questions);
  const highlightColor = useHighlightColor(selectedQuestion.id);
  const isPdf = useSelector(isPdfSelector);
  const textStartPosition = useSelector((state) => state.reader.startPosition);
  const textEndPosition = useSelector((state) => state.reader.endPosition);

  useFirestoreConnect([
    {
      collection: "textLocations",
      doc: `${user_id}`,
      subcollections: [{ collection: "texts" }],
      storeAs: "textLocations"
    }
  ]);
  const lastLocation = useSelector(
    ({ firestore: { data } }) =>
      data.textLocations && data.textLocations[text.id]
  );
  //Ephemeral State
  const [underlinedElement, setUnderlinedElement] = useState(null); // this is the under line that is displayd after clicking show in text

  const [highlights, setHighlights] = useState([]);
  const [underlines, setUnderlines] = useState([]);

  // Derived State
  const isTask = action === "task";
  const isGuidedReading = task.task_type === "guidedReading";
  const isSelectedQuestion = Boolean(selectedQuestion.id);
  //Behavior

  useEffect(() => {
    if (!text.id || text.url) return;
    httpCallables
      .textFunctions({
        func_name: "getTextDownloadUrl",
        courseId: text.course_id,
        fileUrl: text.file_url,
        textId: text.id,
        textType: text.text_type
      })
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setTextUrl({ url: response.data.payload, text_id: text.id })
          );
        }
      });
  }, [dispatch, text.course_id, text.file_url, text.id]);

  async function setUsersHighlightsPerText() {
    let userInteractions = await fetchAllUserHighlightsPerText(text.id);
    if (taskHighlights && taskHighlights.length) {
      userInteractions = userInteractions.concat(taskHighlights);
    }
    userInteractions = userInteractions.filter((obj, index, self) => {
      return index === self.findIndex((t) => t.cfi === obj.cfi);
    });
    if (isCommentsInSecondarySidebar)
      userInteractions = userInteractions.filter(
        (obj) =>
          obj.interaction_type !== "COMMENT" &&
          obj.interaction_type !== "CONTAINER"
      );
    if (userInteractions.length) setHighlights(userInteractions);
    else return;
  }

  // Thi`s effect sets the highlight
  useEffect(() => {
    switch (true) {
      case showAllHighlights:
        setUsersHighlightsPerText();
        break;
      case action === "" && showHighlights && !showAllHighlights:
        setHighlights(readerHighlights);
        break;
      case Boolean(selectedQuestion?.id) && !showAllHighlights:
        setHighlights(questionHighlights);
        break;
      case !isTask && !selectedQuestion?.id && !showAllHighlights:
        setHighlights(grStepOneHighlights);
        break;
      case isTask && !selectedQuestion?.id && !showAllHighlights:
        setHighlights([]);
        break;

      default:
        setHighlights([]);
    }
  }, [
    JSON.stringify(readerHighlights),
    JSON.stringify(questionHighlights),
    JSON.stringify(grStepOneHighlights),
    JSON.stringify(taskHighlights),
    JSON.stringify(socialHighlights),
    action,
    showAllHighlights,
    selectedQuestion,
    step,
    isTask,
    showHighlights
  ]);

  // This effect sets the undelines
  useEffect(() => {
    switch (true) {
      case action === "poc" && step !== "highlight":
        setUnderlines(grStepOneHighlights);
        break;
      case action === "poc" && step === "highlight":
        setUnderlines([]);
        break;
      default:
        setUnderlines([]);
    }
  }, [JSON.stringify(grStepOneHighlights), action, step]);

  useEffect(() => {
    highlightsRef.current = highlights;
  }, [JSON.stringify(highlights)]);

  useEffect(() => {
    /** update ref of higlights for updates state to be used in
     * a callback function for a rendition event */
    if (!highlights.length) return;
    highlightsRef.current = highlights;
    const higlightFound = highlights.find(
      (highlight) => highlight.cfi === underlinedElement
    );
    /** Clear underline if highlight was deleted   */
    if (!higlightFound && rendition) {
      rendition.annotations.remove(underlinedElement, "underline");
      setUnderlinedElement(null);
    }
  }, [JSON.stringify(highlights)]);

  useEffect(() => {
    rendition && rendition.themes.default(fontSize[fontSizeValue]);
  }, [fontSize, fontSizeValue, rendition]);

  useEffect(() => {
    const shownCfi = shownLocation?.cfi;
    if (!shownCfi) return;
    rendition.annotations.remove(underlinedElement, "underline");
    setUnderlinedElement(shownCfi);
  }, [shownLocation?.cfi]);

  useEffect(() => {
    if (underlinedElement) {
      rendition.annotations.underline(
        underlinedElement,
        {},
        () => {},
        ANNOTATION_TYPES.UNDERLINE.toLowerCase(),
        {
          stroke: "none",
          "z-index": -1
        }
      );
    }
  }, [underlinedElement]);

  useEffect(() => {
    if (!rendition || !underlines) return;

    removeAllAnotationsOfType(rendition, "underline");
    // render underlines from redux
    underlines.forEach((underline) => {
      const cfi = underline.cfi;

      rendition.annotations.add(
        "underline",
        cfi,
        { id: underline.id },
        () => {},
        ANNOTATION_TYPES.UNDERLINE.toLowerCase(),
        {
          stroke: "none",
          "z-index": 10,
          "fill-opacity": 0.8,
          fill: "#333333"
        }
      );
    });
  }, [rendition, underlines]);
  useEffect(() => {
    if (!rendition || !highlights) return;

    if (!isCommentsInSecondarySidebar)
      removeAllAnotationsOfType(rendition, ANNOTATION_TYPES.THREAD);
  }, [isCommentsInSecondarySidebar]);

  useEffect(() => {
    if (!rendition || !highlights) return;

    removeAllAnotationsOfType(rendition, ANNOTATION_TYPES.HIGHLIGHT);

    // Function to handle keyboard navigation on highlights
    const handleKeyDown = (event, highlight) => {
      if (
        event.key === "ArrowDown" ||
        event.key === "ArrowUp" ||
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight"
      ) {
        // get actual range in book
        const range = rendition.getRange(highlight.cfi);
        if (range) {
          const textNode = range.startContainer;
          let textElement = textNode.parentElement;
          const elementWindow =
            textNode.ownerDocument.defaultView ||
            textNode.ownerDocument.parentWindow;
          const selection = elementWindow.getSelection();
          // create new range for caret
          const range2 = elementWindow.document.createRange();
          range2.selectNode(textNode);
          if (["ArrowLeft", "ArrowUp"].includes(event.key)) {
            //start
            range2.setStart(textNode, range.startOffset);
          } else {
            //end
            textElement = range.endContainer.parentElement;
            range2.setStart(range.endContainer, range.endOffset);
          }
          range2.collapse(true); // Collapse to start

          textElement.contentEditable = true;
          textElement.focus();
          selection.removeAllRanges();
          selection.addRange(range2);
          requestAnimationFrame(() => {
            textElement.contentEditable = false;
            if (selection.rangeCount === 0) {
              selection.addRange(range2);
            }
          });
        }
      }
      if (event.key === "Enter") {
        const clientRect = event.target.getBoundingClientRect();
        const parentPos = containerRef.current.getBoundingClientRect();

        const highlightedRect = {
          top: clientRect.top + containerRef.current.scrollTop - parentPos.top,
          left:
            clientRect.left + containerRef.current.scrollLeft - parentPos.left,
          x: clientRect.left + containerRef.current.scrollLeft - parentPos.left,
          y: clientRect.top + containerRef.current.scrollTop - parentPos.top,

          width: clientRect.width,
          height: clientRect.height,
          bottom:
            clientRect.top +
            containerRef.current.scrollTop -
            parentPos.top +
            clientRect.height,
          right:
            clientRect.left +
            containerRef.current.scrollLeft -
            parentPos.left +
            clientRect.width
        };

        dispatch(
          setSelectedHighlight({
            clientRectangle: highlightedRect,
            selectedHighlight: highlight.id,
            isHover: true,
            isClickOnMark: true,
            minimal: highlight.minimal
          })
        );
        dispatch(openAnnotatorBar());
      }
    };

    // Render highlights from redux
    highlights.forEach((highlight) => {
      const cfi = highlight.cfi;
      const color = highlight.color;

      rendition.annotations.add(
        ANNOTATION_TYPES.HIGHLIGHT,
        cfi,
        { id: highlight.id },
        () => {},
        ANNOTATION_TYPES.HIGHLIGHT.toLowerCase(),
        {
          "z-index": 20,
          "mix-blend-mode": "multiply",
          "fill-opacity": 0.8,
          fill: getHighlightColor(color, darkMode),
          tabindex: 0, // Make the highlight focusable
          "data-highlight-id": highlight.id, // Add a custom attribute to identify the highlight
          "aria-label": `Highlighted text: "${highlight.content}"`,
          role: "note"
        }
      );

      // Ensure the element is added to the DOM
      const highlightElement = document.querySelector(
        `[data-highlight-id="${highlight.id}"]`
      );
      if (highlightElement) {
        highlightElement.addEventListener("keydown", (event) =>
          handleKeyDown(event, highlight)
        );
      }
    });

    // Cleanup function to remove event listeners
    return () => {
      highlights.forEach((highlight) => {
        const element = document.querySelector(
          `[data-highlight-id="${highlight.id}"]`
        );
        if (element) {
          element.removeEventListener("keydown", (event) =>
            handleKeyDown(event, highlight)
          );
        }
      });
    };
  }, [darkMode, highlights, rendition]);

  useEffect(() => {
    if (rendition && Object.hasOwn(rendition, "View")) {
      rendition.resize();
    }
  }, [width]);

  function onTextSelected(params) {
    const { selection, clientRect } = params;

    let { newHighlight, mergedHighlights } =
      mergeOverlappingHighlightsBySelection(
        highlights,
        false,
        selection,
        rendition
      );

    if (disableInteractions) return;

    switch (true) {
      /** Do Nothing */
      case readOnly:
        return;
      case detachedComment: {
        newHighlight = Object.assign(newHighlight, {
          interaction_type: INTERACTION_TYPES.CONTAINER,
          interaction_subtype: INTERACTION_SUBTYPES.COMMENT
        });
        dispatch(setCustomCursor(false));
        dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.NEW_COMMENT));
        dispatch(setNewCommentObject(newHighlight));
        break;
      }
      /** Update answer quotes */
      case isTask && isGuidedReading && isSelectedQuestion:
      case isTask && task.task_type === "standard" && isSelectedQuestion: {
        newHighlight = Object.assign(newHighlight, {
          interaction_type: INTERACTION_TYPES.ANSWER,
          interaction_subtype: INTERACTION_SUBTYPES.QUOTE,
          interaction_id: selectedQuestion.id,
          task_id: task.id,
          submission_id: submission.id
        });
        addHighlight(newHighlight, mergedHighlights);

        break;
      }

      case action === "" && annotatorMode === "highlight": {
        newHighlight = Object.assign(newHighlight, {
          interaction_type: INTERACTION_TYPES.READER,
          interaction_subtype: INTERACTION_SUBTYPES.QUOTE,
          task_id: task?.id,
          submission_id: submission?.id
        });
        addHighlight(newHighlight, mergedHighlights);
        break;
      }

      case action === "poc" && annotatorMode === "poc": {
        if (!selection.content || !selection.content.trim()) return;
        if (!selectedQuestion.id) {
          if (questions.length) {
            const id = uuid();
            const highlight = {
              id,
              ...newHighlight,
              text_id: selectedTextId,
              course_id: selectedCourse.id,
              interaction_type: INTERACTION_TYPES.ANSWER,
              interaction_subtype: INTERACTION_SUBTYPES.QUOTE,
              color: "rgba(0, 0, 0, 0.12)",
              task_id: task?.id,
              submission_id: submission?.id
            };
            dispatch(addHighlightReducer(highlight));
            clearSelection();
            dispatch(
              setSelectedHighlight({
                clientRectangle: clientRect,
                selectedHighlight: highlight.id,
                isHover: true,
                isClickOnMark: true,
                minimal: highlight.minimal
              })
            );
            dispatch(openAnnotatorBar());
          } else {
            return dispatch(
              toggleNoQuestionsMessage({
                clientRectangle: clientRect
              })
            );
          }
        } else if (step === "highlight") {
          newHighlight = Object.assign(newHighlight, {
            interaction_type: INTERACTION_TYPES.ANSWER,
            interaction_subtype: INTERACTION_SUBTYPES.QUOTE,
            interaction_id: selectedQuestion.id,
            task_id: task?.id,
            submission_id: submission?.id
          });
          addHighlight(newHighlight, mergedHighlights);
        } else {
          if (newHighlight?.content) {
            dispatch(
              toggleAnnotatorBar({
                clientRectangle: clientRect,
                selectedText: newHighlight
              })
            );
          }
        }
        break;
      }

      case !annotatorMode: {
        if (newHighlight.content) {
          dispatch(
            toggleAnnotatorBar({
              clientRectangle: clientRect,
              selectedText: newHighlight
            })
          );
        }
        break;
      }

      default:
        break;
    }
  }

  function addHighlight(highlight, mergedHighlights = []) {
    let textInView = "";
    if (!isPdf && textStartPosition && textEndPosition) {
      textInView = getEpubContentInRange(
        rendition,
        textStartPosition,
        textEndPosition
      );
    } else textInView = "";
    highlight = { ...highlight, textInView };

    interactionsAPI.createHighlight(
      {
        ...highlight,
        color: highlightColor
      },
      selectedTextId,
      selectedCourse.id
    );
    deleteHighlights(mergedHighlights);
    clearSelection(rendition);
  }

  function deleteHighlights(oldIntersectedHighlights) {
    for (const el of oldIntersectedHighlights) {
      interactionsAPI.deleteHighlight(el, false);
    }
  }

  return (
    <>
      <Box ref={containerRef} className={classes.container}>
        <ReactReaderActions
          id="reactReaderActions"
          minimal={minimalBar}
          // highlightFunc={addHighlightToBook}
          addHighlight={addHighlight}
          canComment={true}
          deleteHighlight={interactionsAPI.deleteHighlight}>
          <EpubView
            fullSize={true}
            fontSize={fontSize[fontSizeValue]}
            epubOptions={{ flow: "scrolled-doc" }}
            darkMode={darkMode}
            readOnly={readOnly}
            highlights={highlights}
            handleTextSelected={onTextSelected}
            textDirection={text.text_language === "he" ? "rtl" : "ltr"}
            bodyClassName="gr"
            url={text.url}
            location={lastLocation?.position}
            defaultLocation={text.file_location}
            text_id={text.id}
          />
        </ReactReaderActions>
      </Box>
    </>
  );
}

ReactReader.propTypes = {
  isVisible: PropTypes.bool
};

export default ReactReader;
