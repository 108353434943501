import React from "react";
import TooltipWithIntl from "./tooltip/TooltipWithIntl";
import { IconButton } from "@mui/material";

const HeaderButtonConstructor = React.forwardRef((props, ref) => {
  const {
    disabled = false,
    intlStringId,
    defaultMessage,
    placement,
    iconLabel,
    className,
    icon,
    handleClick
  } = props;

  return (
    <IconButton
      ref={ref}
      aria-label={iconLabel}
      className={className}
      onClick={handleClick}
      disabled={disabled}
      size="large">
      <TooltipWithIntl
        intlStringId={intlStringId}
        defaultMessage={defaultMessage}
        placement={placement}>
        {icon}
      </TooltipWithIntl>
    </IconButton>
  );
});

HeaderButtonConstructor.displayName = "HeaderButtonConstructor";

export default HeaderButtonConstructor;
