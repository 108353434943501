import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { selectCurrentText } from "../../../redux/textsSlice";
import CitationDialog from "../../Tasks/CreateTask/CitationDialog";
import { useSelector } from "react-redux";
import { interactionsAPI } from "../../../api";
import { showInText } from "../../../utils/showInTextHelper";
import { useRendition } from "../../../RenditionContext";
import { appActions } from "../../../consts";

import { useLocation } from "react-router";
import { Cards } from "./Cards";

function CardsView({ context, cards = [], disable = false }) {
  const { rendition } = useRendition();
  const { pathname } = useLocation();

  const user_id = useSelector((state) => state.user.auth.uid);
  const selectedText = useSelector(selectCurrentText);

  const color = pathname.includes("task") ? "primary" : "secondary";

  const [showDialog, setShowDialog] = useState(false);
  const [highlight, setHighlight] = useState([]);

  const showInTextHandler = (answer) => {
    const cb = () => {
      setHighlight([answer]);
      setShowDialog(true);
    };

    showInText(appActions.SHOULD_LOG, answer, user_id, rendition, cb);
  };

  return (
    <>
      <Cards
        disable={disable}
        cards={cards}
        showInText={(answer) => {
          showInTextHandler(answer);
        }}
      />
      {showDialog && (
        <CitationDialog
          color={color}
          setOpenDialog={setShowDialog}
          url={selectedText.url}
          location={selectedText.file_location}
          highlights={highlight}
          openDialog={showDialog}
          context={context}
          removeHighlight={(highlight) => {
            interactionsAPI.deleteHighlight(highlight);
            setHighlight([]);
          }}
          citationTitleFormattedMessage={
            <FormattedMessage
              id="show.location.inText"
              defaultMessage="Show in Text"
            />
          }
        />
      )}
    </>
  );
}

CardsView.propTypes = {
  cards: PropTypes.array, // TODO: add shape definitian of array items
  color: PropTypes.oneOf(["secondary", "primary"]),
  disable: PropTypes.bool
};

export default CardsView;
