import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, query, where } from "firebase/firestore";
import { useSelector } from "react-redux";
import { selectSelectedCourse } from "../redux/coursesSlice";

export const useFeatureFlags = (flag) => {
  const selectedCourse = useSelector(selectSelectedCourse);
  const institutionId = selectedCourse?.institution_id ?? 0;
  const courseId = selectedCourse?.id ?? 0;

  const firestore = useFirestore();
  if (!flag) return false;

  const flagsQuery = query(
    collection(firestore, "featureFlags"),
    where("flag", "==", flag)
  );
  const { data: featureFlags } = useFirestoreCollectionData(flagsQuery);

  if (!featureFlags) return false;

  // Check course level
  const courseFlag = featureFlags.find(
    (doc) => doc.level === "course" && doc.targetId === courseId
  );
  if (courseFlag) return courseFlag.enabled;

  // Check institution level
  const institutionFlag = featureFlags.find(
    (doc) => doc.level === "institution" && doc.targetId === institutionId
  );
  if (institutionFlag) return institutionFlag.enabled;

  // Check default level
  const defaultFlag = featureFlags.find((doc) => doc.level === "default");
  if (defaultFlag) return defaultFlag.enabled;

  // If no flag found, return false
  return false;
};
