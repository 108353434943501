import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo
} from "react";
import PropTypes from "prop-types";
import { PdfHighlight, PdfPosition } from "./PdfTypes";
import { useSelector, useDispatch } from "react-redux";

import { PdfPane, PdfHighlightMark, PdfUnderlineMark } from "./PdfMarks";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { getHighlightColor } from "../../../utils/colors";
import {
  ANNOTATION_TYPES,
  COMMENT_PANEL_VIEW,
  INTERACTION_TYPES
} from "../../../consts";
import PdfCFI from "../../../utils/pdf-cfi";
import {
  selectIsSelectedThreads,
  selectIsSingleThread,
  setCommentPanelState,
  setSelectedRealtimeInteractions,
  setSelectedThreadId
} from "../../../redux/realtimeInteractionsSlice";
import { scrollAnnotationIntoView } from "./utils";
import { setShouldShowLocation } from "../../../redux/pdfSlice";
import { selectDarkMode } from "../../../redux/firestoreSelectors";

// Add a debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const useStyles = makeStyles(() => ({
  highlightLayer: {
    position: "absolute",
    zIndex: 3,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
    userSelect: "none",
    "-webkit-user-select": "none",
    mixBlendMode: "multiply"
  },
  darkModeHighlightLayer: {
    position: "absolute",
    zIndex: 3,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
    userSelect: "none",
    "-webkit-user-select": "none",
    mixBlendMode: "screen"
  }
}));

const MarkTypes = {
  Highlight: "highlight",
  Underline: "underline",
  Thread: "thread"
};

// This effect sets the highlight
const filterHlByPage = (hlCollection, page) => {
  let allHighlights = [];
  if (hlCollection.length) {
    return hlCollection.flatMap((el) => {
      if (el.interaction_type === "SUGGESTION") {
        if (el.pdfPosition.pageNumber === page) {
          return [{ ...el, ...el.pdfPosition }];
        } else return [];
      } else
        return el.pdfPosition.flatMap((pagePart) => {
          if (pagePart.pageNumber === page) {
            return [{ ...el, ...pagePart }];
          } else return [];
        });
    });
  } else return allHighlights;
};

const HighlightLayer = ({
  onHighlightClick,
  highlights = [],
  underlines = [],
  pageNumber,
  rendered
}) => {
  //add scroll to selected Location
  const darkMode = useSelector((state) => selectDarkMode(state));
  const classes = useStyles();
  const ref = useRef();
  const [hlMarks, setHlMarks] = useState([]);
  const [pane, setPane] = useState(null);

  // Use ref instead of state for the border container to prevent re-renders
  const borderContainerRef = useRef(null);

  // Replace individual dimension states with a single dimensions object
  const [dimensions, setDimensions] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    version: 0 // Add a version counter to force updates when needed
  });

  const dispatch = useDispatch();
  const suggestions = useSelector((state) => state.interactions.suggestions);
  const isSelectedThreads = useSelector((state) =>
    selectIsSelectedThreads(state)
  );
  const isSingleThread = useSelector((state) => selectIsSingleThread(state));
  const shouldShowLocation = useSelector(
    (state) => state.pdf.shouldShowLocation
  );
  const tempHighlight = useSelector(
    (state) => state.interactions.selectedTempHighlight
  );
  const [pageHighlights, setPageHighlights] = useState([]);
  const [pageUnderlines, setPageUnderlins] = useState([]);
  const isAnnotatorBarOpen = useSelector(
    (state) => state.highlighter.isAnnotatorBarOpen
  );

  // Use memoized values for highlights and underlines to prevent unnecessary re-renders
  const memoizedPageHighlights = useMemo(
    () => filterHlByPage(highlights, pageNumber),
    [highlights, pageNumber]
  );

  const memoizedPageUnderlines = useMemo(
    () => filterHlByPage(underlines, pageNumber),
    [underlines, pageNumber]
  );

  useEffect(() => {
    setPageUnderlins(memoizedPageUnderlines);
  }, [memoizedPageUnderlines]);

  useEffect(() => {
    setPageHighlights(memoizedPageHighlights);
  }, [memoizedPageHighlights]);

  // Create a CSS style tag for the border styles
  useEffect(() => {
    // Only add the style tag if it doesn't exist
    if (!document.getElementById("highlight-border-styles")) {
      const styleEl = document.createElement("style");
      styleEl.id = "highlight-border-styles";
      styleEl.textContent = `
        .highlight-border-container {
          position: absolute;
          z-index: 19;
          pointer-events: none;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
        .unified-border {
          position: absolute;
          border: 2px solid black;
          border-radius: 3px;
          box-sizing: border-box;
          pointer-events: none;
          /* Make the border more visible */
          box-shadow: 0 0 0 1px white;
        }
        
        /* Additional rule to ensure borders remain visible */
        .textLayer:has(.highlight-border-container) {
          z-index: auto !important;
        }
      `;
      document.head.appendChild(styleEl);

      return () => {
        const styleElement = document.getElementById("highlight-border-styles");
        if (styleElement) {
          styleElement.parentNode.removeChild(styleElement);
        }
      };
    }
  }, [memoizedPageHighlights]);

  // Function to create a unified border around a set of highlight elements
  const createUnifiedBorder = useCallback(
    (elements, highlightId) => {
      if (!elements || elements.length === 0 || !ref.current) {
        return null;
      }

      const textLayerElement =
        ref.current.parentElement.getElementsByClassName("textLayer")[0];
      if (!textLayerElement) {
        return null;
      }

      // Remove existing border with the same ID but keep any other borders
      const existingContainer = textLayerElement.querySelector(
        `.highlight-border-container[data-highlight-id="${highlightId}"]`
      );
      if (existingContainer) {
        existingContainer.parentNode.removeChild(existingContainer);
      }

      // Create border container for the border
      const borderContainer = document.createElement("div");
      borderContainer.className = "highlight-border-container";
      borderContainer.dataset.highlightId = highlightId;
      borderContainer.dataset.timestamp = Date.now(); // Add timestamp to identify latest

      // Calculate the bounding box of all highlight elements
      let minLeft = Infinity;
      let minTop = Infinity;
      let maxRight = -Infinity;
      let maxBottom = -Infinity;

      // First pass to check if we have valid elements
      let validElementCount = 0;
      elements.forEach((element) => {
        if (element && element.element) {
          validElementCount++;
        }
      });

      if (validElementCount === 0) {
        return null;
      }

      // Calculate bounding box
      elements.forEach((element) => {
        if (element && element.element) {
          const rect = element.element.getBoundingClientRect();
          const textLayerRect = textLayerElement.getBoundingClientRect();

          const left = rect.left - textLayerRect.left;
          const top = rect.top - textLayerRect.top;
          const right = left + rect.width;
          const bottom = top + rect.height;

          minLeft = Math.min(minLeft, left);
          minTop = Math.min(minTop, top);
          maxRight = Math.max(maxRight, right);
          maxBottom = Math.max(maxBottom, bottom);
        }
      });

      if (minLeft === Infinity) {
        return null;
      }

      // Add padding
      const padding = 2;
      minLeft -= padding;
      minTop -= padding;
      maxRight += padding;
      maxBottom += padding;

      // Create the border element
      const borderElement = document.createElement("div");
      borderElement.className = "unified-border";
      borderElement.style.left = `${minLeft}px`;
      borderElement.style.top = `${minTop}px`;
      borderElement.style.width = `${maxRight - minLeft}px`;
      borderElement.style.height = `${maxBottom - minTop}px`;

      borderContainer.appendChild(borderElement);
      textLayerElement.appendChild(borderContainer);

      // Store the reference in the ref
      borderContainerRef.current = borderContainer;

      console.log(
        `Created unified border for highlight ${highlightId} with dimensions:`,
        {
          left: minLeft,
          top: minTop,
          width: maxRight + minLeft,
          height: maxBottom - minTop
        }
      );

      return borderContainer;
    },
    [ref]
  );

  // Add resize observer to track element dimensions with debouncing
  useEffect(() => {
    if (!ref.current) return;

    const updateDimensions = debounce(() => {
      if (!ref.current) return;

      const boundingRect = ref.current.getBoundingClientRect();

      // Only update if dimensions change significantly (e.g., more than 5px)
      setDimensions((prev) => {
        const newDims = {
          left: boundingRect.left,
          top: boundingRect.top,
          width: boundingRect.width,
          height: boundingRect.height,
          version: prev.version
        };

        // Always update on the first run to ensure proper initialization
        if (prev.version === 0) {
          newDims.version = 1;
          return newDims;
        }

        // Check if there's a significant change
        if (
          Math.abs(prev.left - newDims.left) > 5 ||
          Math.abs(prev.top - newDims.top) > 5 ||
          Math.abs(prev.width - newDims.width) > 5 ||
          Math.abs(prev.height - newDims.height) > 5
        ) {
          // Increment version to force update
          newDims.version = prev.version + 1;
          return newDims;
        }

        return prev;
      });
    }, 200); // 200ms debounce

    // Create ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });

    resizeObserver.observe(ref.current);

    // Also update on window resize for safety
    window.addEventListener("resize", updateDimensions);

    // Initial dimensions setup
    updateDimensions();

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("resize", updateDimensions);
    };
  }, [ref.current]);

  useEffect(() => {
    if (ref.current && !pane && rendered) {
      const pageElement = ref.current;
      setPane(new PdfPane(pageElement.parentElement, pageElement));
    }
    if (!rendered && pane) {
      for (const annotation of [...hlMarks]) {
        if (!annotation) continue;
        pane.removeMark(annotation.mark);
      }

      // Clean up any border container
      if (borderContainerRef.current && borderContainerRef.current.parentNode) {
        borderContainerRef.current.parentNode.removeChild(
          borderContainerRef.current
        );
        borderContainerRef.current = null;
      }
    }
  }, [ref, pane, rendered, hlMarks]);

  // Only clean up border when tempHighlight becomes null
  useEffect(() => {
    if (
      !tempHighlight &&
      borderContainerRef.current &&
      borderContainerRef.current.parentNode
    ) {
      borderContainerRef.current.parentNode.removeChild(
        borderContainerRef.current
      );
      borderContainerRef.current = null;
    }
  }, [tempHighlight]);

  // Cache the mark creation function to prevent recreating it on each render
  const createMark = useCallback(
    (type, cfiRange, data = {}, cb, className = "epubjs-hl", styles = {}) => {
      if (!ref.current || !pane) {
        return null;
      }

      const attributes = Object.assign({}, styles);

      const textLayerElement =
        ref.current.parentElement.getElementsByClassName("textLayer")[0];

      if (!textLayerElement) {
        return null;
      }

      let range = cfiRange.toRange(textLayerElement);

      if (!range) {
        console.warn(`Range not found for cfi: ${cfiRange}, id: ${data.id}`);
        return null;
      }

      let m =
        type == MarkTypes.Highlight || type == MarkTypes.Thread
          ? new PdfHighlightMark(range, className, data, attributes)
          : new PdfUnderlineMark(range, className, data, attributes);

      let h = pane.addMark(m);

      if (type === ANNOTATION_TYPES.HIGHLIGHT.toLowerCase()) {
        boundEventListenerToHighlight(h, data, className, cfiRange);
      } else if (type === MarkTypes.Thread) {
        h.element.setAttribute("ref", className);
        h.element.addEventListener("click", (e) => {
          cb(e);
        });
      }

      // For temporary highlights, add a data attribute to identify them
      if (data.id === tempHighlight?.id) {
        h.element.setAttribute("data-temp-highlight", "true");
      }

      const highlightedText = range.toString().replace(/^Note: /, "");
      h.element.setAttribute("role", "note");
      h.element.setAttribute(
        "aria-label",
        `Highlighted text: "${highlightedText}"`
      );
      return { mark: h, element: h.element, listeners: [cb] };
    },
    [pane, ref, tempHighlight]
  );

  // Consolidate event listeners into a single function
  const boundEventListenerToHighlight = useCallback(
    (h, data, className, cfiRange) => {
      if (!h || !h.element) return;

      h.element.setAttribute("ref", className);

      const handleClick = (e) => {
        onHighlightClick(e, data, isAnnotatorBarOpen);
        handleClickOnNestedElement(e);
      };

      h.element.addEventListener("click", handleClick);
      h.element.addEventListener("touchstart", handleClick);
      h.element.addEventListener("keydown", (e) => {
        if (
          e.key === "ArrowDown" ||
          e.key === "ArrowUp" ||
          e.key === "ArrowLeft" ||
          e.key === "ArrowRight"
        ) {
          const textLayerElement =
            ref.current?.parentElement?.getElementsByClassName("textLayer")[0];
          if (!textLayerElement) return;

          let range = cfiRange.toRange(textLayerElement);
          if (!range) return;

          // Create new range
          const textNode = range.startContainer;
          let textElement = textNode.parentElement;
          const elementWindow =
            textNode.ownerDocument.defaultView ||
            textNode.ownerDocument.parentWindow;
          const selection = elementWindow.getSelection();
          // create new range for caret
          const range2 = elementWindow.document.createRange();
          range2.selectNode(textNode);
          if (["ArrowLeft", "ArrowUp"].includes(e.key)) {
            // Fixed: was using event.key
            //start
            range2.setStart(textNode, range.startOffset);
          } else {
            //end
            textElement = range.endContainer.parentElement;
            range2.setStart(range.endContainer, range.endOffset);
          }
          range2.collapse(true); // Collapse to start

          textElement.contentEditable = true;
          textElement.focus();
          selection.removeAllRanges();
          selection.addRange(range2);
          requestAnimationFrame(() => {
            textElement.contentEditable = false;
            if (selection.rangeCount === 0) {
              selection.addRange(range2);
            }
          });
        }

        if (e.key === "Enter") {
          const ce = {};
          for (const prop in e) {
            if (typeof e[prop] !== "function") {
              ce[prop] = e[prop];
            }
          }
          ce.detail = [data];
          ce.target = h;
          onHighlightClick(ce, data, isAnnotatorBarOpen);
          handleClickOnNestedElement(ce);
          e.stopPropagation();
        }
      });

      // Store the reference to event handlers for potential cleanup
      h._eventHandlers = { click: handleClick };
    },
    [ref, onHighlightClick, isAnnotatorBarOpen]
  );

  // State to track if we need to force a render for tempHighlight
  const [forceRender, setForceRender] = useState(0);

  // Force a re-render when tempHighlight changes
  useEffect(() => {
    if (tempHighlight) {
      setForceRender((prev) => prev + 1);
    }
  }, [tempHighlight]);

  // Main useEffect for handling highlights
  useEffect(() => {
    if (!pane || !rendered) {
      console.warn(
        "Pane or rendered is not available, skipping highlight rendering"
      );
      return;
    }

    // Clean up existing highlights
    for (const annotation of [...hlMarks]) {
      if (!annotation) continue;
      pane.removeMark(annotation.mark);
    }

    // Only clean up the border container if tempHighlight has changed
    // or if we're not currently processing a tempHighlight
    if (borderContainerRef.current && borderContainerRef.current.parentNode) {
      if (
        !tempHighlight ||
        (borderContainerRef.current.dataset.highlightId &&
          borderContainerRef.current.dataset.highlightId !== tempHighlight.id)
      ) {
        borderContainerRef.current.parentNode.removeChild(
          borderContainerRef.current
        );
        borderContainerRef.current = null;
      }
    }

    // render highlights from redux
    let highlightElements = [];
    let tempHighlightElements = [];

    pageHighlights.forEach((highlight) => {
      const { color, interaction_type, id } = highlight;
      const cfi = new PdfCFI(highlight.cfi);

      if (interaction_type === INTERACTION_TYPES.CONTAINER) {
        const container = createMark(
          MarkTypes.Thread,
          cfi,
          { id: highlight.id },
          populateThreadsIds,
          MarkTypes.Thread,
          {
            "z-index": 15,
            "mix-blend-mode": "multiply",
            "fill-opacity": 0.8,
            fill: "rgba(0, 0, 0, 0.12)",
            tabindex: 0
          }
        );

        if (container) highlightElements.push(container);
        scrollToHighlightIfNeeded(highlight.id);
      } else {
        // Create normal highlight (for all highlights, including temp)
        const highlightItem = createMark(
          MarkTypes.Highlight,
          cfi,
          { id: highlight.id },
          () => {},
          id === tempHighlight?.id ? "tempHighlightClass" : "highlightClass",
          darkMode
            ? {
                "z-index": id === tempHighlight?.id ? 10 : 1,
                "mix-blend-mode": "normal",
                "fill-opacity": 0.6,
                fill: getHighlightColor(color, darkMode),
                tabindex: 0,
                "force-text-color": "black"
              }
            : {
                "z-index":
                  interaction_type === INTERACTION_TYPES.SUGGESTION
                    ? 5
                    : id === tempHighlight?.id
                      ? 10
                      : 1,
                "mix-blend-mode": "multiply",
                "fill-opacity":
                  interaction_type === INTERACTION_TYPES.SUGGESTION ? 0.6 : 0.8,
                fill: getHighlightColor(color, darkMode),
                tabindex: 0
              }
        );

        if (highlightItem) {
          highlightElements.push(highlightItem);

          // If this is the temporary highlight, store it separately
          if (id === tempHighlight?.id) {
            tempHighlightElements.push(highlightItem);
          }
        }

        scrollToHighlightIfNeeded(highlight.id);
      }
    });

    setHlMarks(highlightElements);

    // Create unified border for temp highlight if there are elements
    if (tempHighlightElements.length > 0 && tempHighlight) {
      // Use requestAnimationFrame for more reliable DOM timing
      requestAnimationFrame(() => {
        // Double RAF for extra safety to ensure elements are rendered
        requestAnimationFrame(() => {
          const border = createUnifiedBorder(
            tempHighlightElements,
            tempHighlight.id
          );
          // Set a flag on the border to prevent it from being removed immediately
          if (border) {
            border.dataset.persistent = "true";
            // Set a long timeout to check if the border is still in the DOM
          }
        });
      });
    }
  }, [
    darkMode,
    JSON.stringify(pageHighlights),
    JSON.stringify(pageUnderlines),
    pane,
    rendered,
    dimensions.version,
    tempHighlight,
    forceRender,
    createMark,
    createUnifiedBorder
  ]);

  const scrollToHighlightIfNeeded = useCallback(
    (highlight) => {
      if (highlight === shouldShowLocation) {
        scrollAnnotationIntoView(shouldShowLocation);
        dispatch(setShouldShowLocation(null));
      }
    },
    [shouldShowLocation, dispatch]
  );

  const populateThreadsIds = useCallback((data) => {
    let threadIds = handleClickOnThreadHighlight(data);
    dispatchSelectedThreads(threadIds);
  }, []);

  const handleClickOnThreadHighlight = useCallback((e) => {
    const comments = e.detail;
    let threadIds = comments.map((comment) => comment.id);
    threadIds = [...new Set(threadIds)];
    return threadIds;
  }, []);

  const handleClickOnNestedElement = useCallback((data) => {
    return handleClickOnHighlightElement(data);
  }, []);

  const handleClickOnHighlightElement = useCallback(
    (e) => {
      const elements = e.detail;
      const filteredElements = elements.filter(
        (element) =>
          !suggestions.some((suggestion) => suggestion.id === element.id)
      );

      return onHighlightClick(e, filteredElements[0], isAnnotatorBarOpen);
    },
    [onHighlightClick, suggestions, isAnnotatorBarOpen]
  );

  const dispatchSelectedThreads = useCallback(
    (threadIds) => {
      if (threadIds.length !== 1) {
        dispatch(setSelectedRealtimeInteractions(threadIds));
        if (!isSelectedThreads)
          dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.SELECTED_THREADS));
      } else {
        dispatch(setSelectedThreadId(threadIds[0].toString()));
        if (!isSingleThread)
          dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.SINGLE_THREAD));
      }
    },
    [dispatch, isSelectedThreads, isSingleThread]
  );

  return (
    <div
      ref={ref}
      style={{ pointerEvents: "none" }}
      className={clsx(
        darkMode ? classes.darkModeHighlightLayer : classes.highlightLayer,
        "highlightLayer"
      )}></div>
  );
};

HighlightLayer.propTypes = {
  selectedLocation: PropTypes.shape(PdfPosition),
  highlights: PropTypes.arrayOf(PropTypes.shape(PdfHighlight)),
  underlines: PropTypes.arrayOf(PropTypes.shape(PdfHighlight)),
  onHighlightClick: PropTypes.func.isRequired,
  rendered: PropTypes.number,
  pageNumber: PropTypes.number
};

export default HighlightLayer;
