import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { PRIVACY } from "../../consts";
import commentsAPI from "../../api/comments";
// Redux dependencies
import { useSelector } from "react-redux";
import { selectCurrentText } from "../../redux/textsSlice";
import { selectThread } from "../../redux/realtimeInteractionsSlice";
//Components

// Material UI
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Comment from "./Comment";
import { useQuery } from "../../hooks";
import NewTextEditor from "../SharedComponents/textEditor/NewTextEditor";
import { useIntl } from "react-intl";
import {
  selectAvatar,
  selectCommentsPrivacy
} from "../../redux/firestoreSelectors";
import { CustomAvatar } from "../SharedComponents";
import NavigateBackButton from "./NavigateBackButton";

const useStyles = makeStyles((theme) => ({
  threadContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 120px)",
    // maxHeight: "calc(100vh - 220px)",
    maxWidth: "240px",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    fontFamily: "Chivo"
  },
  threadTopSection: {
    display: "inline-flex",
    maringTop: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    width: "100%",
    paddingBottom: theme.spacing(0.3),
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexDirection: "row",
    backgroundColor: theme.palette.background.rightSidePanel,
    zIndex: 10,
    position: "fixed"
  },
  threadHeader: {
    display: "flex",
    color: theme.palette.primary,
    paddingTop: theme.spacing(1.4),
    fontWeight: 400,
    letterSpacing: "1px",
    width: "auto",
    textDecoration: "none",
    alignContent: "center",
    fontSize: "12px"
  },
  outlined: {
    border: `1px solid ${theme.palette.divider}`
  },
  threadReplySection: {
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flexFlow: "row",
    width: "100%",
    height: "auto"
  },
  thread: {
    display: "flex",
    paddingTop: theme.spacing(6),
    width: "100%",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
    overflowY: "scroll"
  },
  newThreadComment: {
    width: "100%",
    bottom: 0,
    maxHeight: "450px"
  },
  userInput: {
    zIndex: 2,
    maxHeight: "250px",
    maxWidth: "250px",
    overflow: "auto",
    fontSize: theme.typography.fontSize,
    paddingInline: theme.spacing(2),
    marginBlockStart: theme.spacing(0.5),
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  threadComments: {
    zIndex: 0,
    width: "100%"
  },
  buttons: {
    bottom: 0,
    color: theme.palette.text.success,
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "13px",
    marginBlockEnd: "8px",
    fontWeight: 400,
    width: "50px",
    letterSpacing: "1px",
    "&:hover": {
      textDecoration: "none"
    },
    paddingInline: theme.spacing(2),
    marginBlockStart: theme.spacing(1.5)
  },
  button: {
    color: theme.palette.secondary.contrast,
    backgroundColor: theme.palette.secondary.main,
    paddingBlock: theme.spacing(0.5),
    padding: 0,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.text.main
    }
  },
  minimizedAvatar: {
    height: "26px",
    width: "26px",
    fontSize: "small",
    marginBlockStart: theme.spacing(1)
  },
  userName: {
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  nameAndAvatar: {
    display: "inline-flex",
    alignItems: "center",
    marginInlineStart: theme.spacing(2)
  }
}));
const Thread = () => {
  // Hooks
  const classes = useStyles();
  let { text_id } = useQuery();
  const intl = useIntl();
  // Ephemeral state
  const [sortedThreadcomments, setSortedThreadComments] = useState([]);
  const [comment, setComment] = useState({});
  const editorRef = useRef(null); // Add this ref to hold editor instance

  // Redux State
  const selectedThreadId = useSelector(
    (state) => state.realtimeInteractions.selectedThreadId
  );
  const user_name = useSelector((state) => state.user.auth.displayName);
  const currentUser = useSelector((state) => state.firebase.auth);
  const selectedCourseId = useSelector(
    (state) => state.user.userProfile.selectedCourseId
  );

  const container = useSelector((state) =>
    selectThread(state, selectedThreadId)
  );
  const text = useSelector(selectCurrentText);
  const avatar = useSelector((state) => selectAvatar(state));

  const selectCommentPrivacyMode = useSelector((state) =>
    selectCommentsPrivacy(state)
  );

  async function createCommentOnThread(content) {
    let course_id;
    const { richText, plainText, wordCount } = content;
    const { cfi } = container[0];

    if (!text_id) {
      course_id = selectedCourseId;
      text_id = text.id;
    } else {
      course_id = text.course_id;
    }

    const interaction = {
      cfi: cfi,
      content: plainText,
      rich_text: richText,
      word_count: wordCount,
      course_id: course_id,
      interaction_id: selectedThreadId,
      text_id: text_id,
      privacy: selectCommentPrivacyMode,
      user_name: user_name,
      text_name: text.name
    };
    commentsAPI.createComment(interaction).then((success) => {
      if (success) {
        //TODO: Add snackbar
        // implament undo
        setComment({});
        if (editorRef.current?.editor) {
          editorRef.current.editor.commands.clearContent();
        }
      }
    });
  }

  useEffect(() => {
    if (!container) return;
    setSortedThreadComments(container);
  }, [container]);

  return (
    <>
      <Box className={clsx(classes.threadContainer, classes.outlined)}>
        <Box className={classes.threadTopSection}>
          <NavigateBackButton />
          <Box>
            <Typography
              component="span"
              variant="subtitle2"
              className={classes.threadHeader}>
              {sortedThreadcomments.length &&
              sortedThreadcomments[0].privacy === PRIVACY.PUBLIC
                ? intl.formatMessage({
                    id: "comments.community.single",
                    defaultMessage: "Community"
                  })
                : intl.formatMessage({
                    id: "comments.private.single",
                    defaultMessage: "Private"
                  })}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.thread}>
          {sortedThreadcomments &&
            sortedThreadcomments.map((comment, index) => {
              return (
                <Box className={classes.threadComments} key={index}>
                  <Comment
                    index={index}
                    comment={comment}
                    setSortedThread={setSortedThreadComments}
                  />
                  {index === 0 && (
                    <Typography
                      component="span"
                      variant="subtitle2"
                      className={classes.threadReplySection}>
                      {"Replies"}
                    </Typography>
                  )}
                </Box>
              );
            })}
        </Box>
        <Box className={classes.newThreadComment}>
          <Box className={classes.nameAndAvatar}>
            <CustomAvatar
              className={classes.minimizedAvatar}
              id={currentUser.uid}
              name={currentUser.displayName}
              src={avatar}
              minimized={true}
            />
            <Typography variant="body2" className={classes.userName}>
              {"Me"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              "& .editorContainer ": { padding: "16px", borderWidth: "0" },
              "& .editor ": { minHeight: "50px" }
            }}>
            <NewTextEditor
              ref={editorRef}
              onContentChange={(content) => {
                setComment(content);
              }}
              onEnterPress={() => {
                createCommentOnThread(comment);
                setComment({});
              }}
              placeholder={intl.formatMessage({
                id: "comments.thread.reply",
                defaultMessage: "My reply"
              })}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                paddingLeft: "16px"
              }}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                disableElevation
                className={classes.button}
                disabled={
                  comment?.plainText?.length === 0 ||
                  Object.keys(comment).length === 0
                }
                onClick={() => {
                  createCommentOnThread(comment);
                  setComment({});
                }}>
                {intl.formatMessage({
                  id: "comments.thread.postReply",
                  defaultMessage: "Reply"
                })}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Thread;
